
import { FormPage } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { InputText } from '@/components/inputs'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'
import FieldCheckbox from '@/components/forms/fields/FieldCheckbox.vue'
import FieldText from '@/components/forms/fields/FieldText.vue'
import { Services } from 'tradingmate-modules/src/services'
import BusinessBranchFeedbackFilter
  from 'tradingmate-modules/src/filters/BusinessBranchFeedbackFilter'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import BusinessBranchWithFeedbackReadDto
  from 'tradingmate-modules/src/models/api/businesses/branches/Feedback/BusinessBranchWithFeedbackReadDto'
import Utils from 'tradingmate-modules/src/Utils'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import Paging from '@/components/actions/Paging.vue'

@Component({
  components: {
    FieldText,
    FieldCheckbox,
    FormCheckboxField,
    InputText,
    Table,
    TableRow,
    TableData,
    TableHeader,
    Paging
  }
})
export default class Feedback extends Mixins(FormPage) {
  private feedback: PagedResultSet<BusinessBranchWithFeedbackReadDto> | null = null;
  private filter: BusinessBranchFeedbackFilter = new BusinessBranchFeedbackFilter()
  private isExporting = false

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getFeedback()
  }

  getFeedback (): void {
    this.loading = true
    Services.API.Businesses.FeedbackQuery(this.filter)
      .then((results) => {
        this.feedback = results
      }).finally(() => { this.loading = false })
  }

  onPaged (page: number): void {
    this.filter.Page = page
    this.getFeedback()
  }

  onExport (): void {
    this.isExporting = true
    Services.API.Admin.Businesses.ExportFeedback(this.filter)
      .then((response) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(response)
        link.download = 'feedback.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      }).finally(() => {
        this.isExporting = true
      })
  }
}
